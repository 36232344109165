import {
  CheckboxActive,
  CheckboxDefault,
  RadioActiveIcon,
  RadioInactiveIcon,
} from '@/assets/svg';
import { formatOldData } from '@/helpers';
import { FIELD_TYPES_ENUM } from '@/module/checkin-patient-information/components/TabContainers/ModalSelectCategory/constants';
import {
  withStyles,
  Radio,
  Grid,
  Card,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box,
  Divider,
  CardContent,
} from '@material-ui/core';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';

const CustomTitle = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    wordBreak: 'break-word',
    lineHeight: '30px',
  },
})(Typography);

const CustomCard = withStyles({
  root: { borderColor: '#e1e1e1' },
})(Card);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },

    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& p': {
      marginTop: 1,
    },
  },
})(TextField);

const CustomSurveyItem = ({ data, onChangeDataSelected, dataExist }) => {
  const [valueDropdown, setValueDropdown] = useState('');
  const [valueRating, setValueRating] = useState('');
  const [listMultiSelect, setListMultiSelect] = useState({});
  const [formValue, setFormValue] = useState([]);

  const handleChangeForm = (e, formItem) => {
    const { customFieldType } = formItem || {};
    const { value } = e.target;
    let newFormValue = cloneDeep(formValue);
    const updatedIdx = newFormValue?.findIndex(
      (item) => item.id === formItem.id
    );

    switch (customFieldType) {
      case FIELD_TYPES_ENUM.TEXT:
        if (updatedIdx >= 0) {
          newFormValue[updatedIdx].value = value;
        }

        onChangeDataSelected(newFormValue[updatedIdx], FIELD_TYPES_ENUM.TEXT);
        setFormValue(newFormValue);
        break;

      case FIELD_TYPES_ENUM.DROPDOWNLIST:
        if (updatedIdx >= 0) {
          newFormValue[updatedIdx].value = [value];
        }
        if (value === 'Others') {
          newFormValue[updatedIdx].isOther = value === 'Others';
        }

        onChangeDataSelected(
          newFormValue[updatedIdx],
          FIELD_TYPES_ENUM.DROPDOWNLIST
        );

        setValueDropdown((prevState) => ({
          ...prevState,
          [formItem.id]: value,
        }));
        break;

      case FIELD_TYPES_ENUM.MULTISELECT:
        const selectedIndex = listMultiSelect[formItem.id]
          ? listMultiSelect[formItem.id].indexOf(value)
          : -1;
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(
            listMultiSelect[formItem.id] || [],
            value
          );
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(
            listMultiSelect[formItem.id].slice(1)
          );
        } else if (selectedIndex === listMultiSelect[formItem.id].length - 1) {
          newSelected = newSelected.concat(
            listMultiSelect[formItem.id].slice(0, -1)
          );
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            listMultiSelect[formItem.id].slice(0, selectedIndex),
            listMultiSelect[formItem.id].slice(selectedIndex + 1)
          );
        }
        if (updatedIdx >= 0) {
          newFormValue[updatedIdx].value = newSelected;
        }

        if (value === 'Others') {
          newFormValue[updatedIdx].isOther = newSelected.includes('Others');
        }

        onChangeDataSelected(
          newFormValue[updatedIdx],
          FIELD_TYPES_ENUM.MULTISELECT
        );
        setListMultiSelect((prevState) => ({
          ...prevState,
          [formItem.id]: newSelected,
        }));

        break;

      case FIELD_TYPES_ENUM.RATING:
        if (updatedIdx >= 0) {
          newFormValue[updatedIdx].value = [value];
        }
        if (value === 'Others') {
          newFormValue[updatedIdx].isOther = newSelected.includes('Others');
        }

        onChangeDataSelected(newFormValue[updatedIdx], FIELD_TYPES_ENUM.RATING);
        setValueRating((prevState) => ({
          ...prevState,
          [formItem.id]: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeOtherValue = (e, formItem) => {
    const { value } = e.target;
    let newFormValue = cloneDeep(formValue);
    let newFormValueDefault = cloneDeep(formValue);

    const updatedIdx = newFormValue?.findIndex(
      (item) => item.id === formItem.id
    );
    if (formItem.customFieldType === FIELD_TYPES_ENUM.MULTISELECT) {
      const selectedIndex = listMultiSelect[formItem.id]
        ? listMultiSelect[formItem.id].indexOf(value)
        : -1;
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          listMultiSelect[formItem.id] || [],
          value
        );
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(listMultiSelect[formItem.id].slice(1));
      } else if (selectedIndex === listMultiSelect[formItem.id].length - 1) {
        newSelected = newSelected.concat(
          listMultiSelect[formItem.id].slice(0, -1)
        );
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          listMultiSelect[formItem.id].slice(0, selectedIndex),
          listMultiSelect[formItem.id].slice(selectedIndex + 1)
        );
      }
      newSelected = newSelected.filter((it) => it !== value);
      newFormValue[updatedIdx].value = newSelected;
      newFormValue[updatedIdx].otherAnswer = value;
    } else if (formItem.customFieldType === FIELD_TYPES_ENUM.DROPDOWNLIST) {
      newFormValue[updatedIdx].value = [valueDropdown[formItem.id]];
      newFormValue[updatedIdx].otherAnswer = value;
    }

    onChangeDataSelected(newFormValue[updatedIdx], formItem.customFieldType);
    newFormValueDefault[updatedIdx].otherAnswer = value;
    setFormValue(newFormValueDefault);
  };

  useEffect(() => {
    if (!isEmpty(data) && isEmpty(dataExist)) {
      const newData = data.map((item) => ({
        ...item,
        additionalNote: item.additionalNote,
        hasAdditional: item.hasAdditional,
      }));
      setFormValue(newData);
    } else {
      let newFromValue = [...formValue];

      dataExist.forEach((item) => {
        const updatedIdx = newFromValue?.findIndex(
          (formItem) => formItem.id === item.fieldId
        );
        if (item.customFieldType === FIELD_TYPES_ENUM.TEXT) {
          if (updatedIdx < 0) return;
          newFromValue[updatedIdx].value = item.value;
        } else if (item.customFieldType === FIELD_TYPES_ENUM.DROPDOWNLIST) {
          setValueDropdown((prevState) => ({
            ...prevState,
            [item.fieldId]: isArray(item.value) ? item.value[0] : item.value,
          }));
          if (updatedIdx < 0) return;
          newFromValue[updatedIdx].otherAnswer = item.otherAnswer;
        } else if (item.customFieldType === FIELD_TYPES_ENUM.RATING) {
          setValueRating((prevState) => ({
            ...prevState,
            [item.fieldId]: isArray(item.value) ? item.value[0] : item.value,
          }));
          if (updatedIdx < 0) return;
          newFromValue[updatedIdx].otherAnswer = item.otherAnswer;
        } else {
          setListMultiSelect((prevState) => ({
            ...prevState,

            [item.fieldId]: item.value,
          }));
          if (updatedIdx < 0) return;
          newFromValue[updatedIdx].otherAnswer = item.otherAnswer;
        }
      });

      setFormValue(newFromValue);
    }

    // eslint-disable-next-line
  }, [dataExist]);

  const renderFormItem = (formItem, index) => {
    const { customFieldType } = formItem || {};
    switch (customFieldType) {
      case FIELD_TYPES_ENUM.TEXT:
        return (
          <Grid key={formItem.id} item xs={12}>
            <CustomCard variant="outlined">
              <Box px={2.5} py={1.5}>
                <CustomTitle
                  dangerouslySetInnerHTML={{
                    __html: index + 1 + '. ' + formatOldData(formItem.label),
                  }}
                />
                <CustomTitle
                  dangerouslySetInnerHTML={{
                    __html: formatOldData(formItem.description),
                  }}
                />
              </Box>
              <Divider light />
              <CardContent>
                <CustomTextField
                  fullWidth
                  multiline
                  rows={3}
                  value={formItem?.value || ''}
                  onChange={(e) => handleChangeForm(e, formItem)}
                  placeholder="Enter your answer"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </CardContent>
            </CustomCard>
          </Grid>
        );

      case FIELD_TYPES_ENUM.MULTISELECT:
        return (
          <Grid key={formItem.id} item xs={12}>
            <CustomCard variant="outlined">
              <Box px={2.5} py={1.5}>
                <CustomTitle>
                  {index + 1}. {formItem.label}
                </CustomTitle>
                <CustomTitle>Select multiple</CustomTitle>
              </Box>
              <Divider light />
              <CardContent>
                <FormGroup>
                  {formItem?.value
                    ? formItem.value?.map((item) => {
                        const isItemSelected =
                          listMultiSelect[formItem.id]?.indexOf(item) >= 0;
                        return item === 'Others' ? (
                          <>
                            <FormControlLabel
                              key={item}
                              value={item}
                              checked={isItemSelected}
                              control={
                                <Checkbox
                                  icon={<CheckboxDefault />}
                                  checkedIcon={<CheckboxActive />}
                                  color="primary"
                                />
                              }
                              label={`${item}`}
                              onChange={(e) => handleChangeForm(e, formItem)}
                            />
                            {formItem?.hasAdditional ? (
                              <CustomTextField
                                fullWidth
                                multiline
                                disabled={!isItemSelected}
                                rows={3}
                                value={formItem?.otherAnswer || ''}
                                onChange={(e) =>
                                  handleChangeOtherValue(e, formItem)
                                }
                                placeholder={
                                  formItem.additionalNote || 'Enter your answer'
                                }
                                variant="outlined"
                              />
                            ) : null}
                          </>
                        ) : (
                          <FormControlLabel
                            key={item}
                            value={item}
                            checked={isItemSelected}
                            control={
                              <Checkbox
                                icon={<CheckboxDefault />}
                                checkedIcon={<CheckboxActive />}
                                color="primary"
                              />
                            }
                            label={`${item}`}
                            onChange={(e) => handleChangeForm(e, formItem)}
                          />
                        );
                      })
                    : null}
                </FormGroup>
              </CardContent>
            </CustomCard>
          </Grid>
        );

      case FIELD_TYPES_ENUM.DROPDOWNLIST:
        return (
          <Grid key={formItem.id} item xs={12}>
            <CustomCard variant="outlined">
              <Box px={2.5} py={1.5}>
                <CustomTitle>
                  {index + 1}. {formItem.label}
                </CustomTitle>
                <CustomTitle>Select one only</CustomTitle>
              </Box>
              <Divider light />
              <CardContent>
                <RadioGroup
                  value={valueDropdown[formItem?.id]}
                  onChange={(e) => handleChangeForm(e, formItem)}
                >
                  {formItem?.value
                    ? formItem.value.map((item) => {
                        return item === 'Others' ? (
                          <>
                            <FormControlLabel
                              key={item}
                              value={item}
                              checked={item === valueDropdown[formItem?.id]}
                              control={
                                <Radio
                                  icon={<RadioInactiveIcon />}
                                  checkedIcon={<RadioActiveIcon />}
                                  color="primary"
                                />
                              }
                              label={`${item}`}
                              onChange={(e) => handleChangeForm(e, formItem)}
                            />
                            {formItem?.hasAdditional ? (
                              <CustomTextField
                                fullWidth
                                multiline
                                disabled={item !== valueDropdown[formItem?.id]}
                                rows={3}
                                value={formItem?.otherAnswer || ''}
                                onChange={(e) =>
                                  handleChangeOtherValue(e, formItem)
                                }
                                placeholder={
                                  formItem.additionalNote || 'Enter your answer'
                                }
                                variant="outlined"
                              />
                            ) : null}
                          </>
                        ) : (
                          <FormControlLabel
                            value={item}
                            checked={item === valueDropdown[formItem?.id]}
                            control={
                              <Radio
                                icon={<RadioInactiveIcon />}
                                checkedIcon={<RadioActiveIcon />}
                                color="primary"
                              />
                            }
                            label={`${item}`}
                          />
                        );
                      })
                    : null}
                </RadioGroup>
              </CardContent>
            </CustomCard>
          </Grid>
        );

      case FIELD_TYPES_ENUM.RATING:
        return (
          <Grid key={formItem.id} item xs={12}>
            <CustomCard variant="outlined">
              <Box px={2.5} py={1.5}>
                <CustomTitle>
                  {index + 1}. {formItem.label}
                </CustomTitle>
                <CustomTitle>Select one only</CustomTitle>
              </Box>
              <Divider light />
              <CardContent>
                <RadioGroup
                  value={valueRating[formItem?.id]}
                  onChange={(e) => handleChangeForm(e, formItem)}
                  row
                >
                  {formItem?.value
                    ? formItem.value.map((item) => {
                        return (
                          <FormControlLabel
                            value={item}
                            checked={item === valueRating[formItem?.id]}
                            control={
                              <Radio
                                icon={<RadioInactiveIcon />}
                                checkedIcon={<RadioActiveIcon />}
                                color="primary"
                              />
                            }
                            label={`${item}`}
                          />
                        );
                      })
                    : null}
                </RadioGroup>
              </CardContent>
            </CustomCard>
          </Grid>
        );

      default:
        break;
    }
  };

  return isEmpty(data) ? (
    <p>This project doesn't have custom</p>
  ) : (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      {formValue?.map((formItem, index) => {
        const formItemComponent = renderFormItem(formItem, index);
        return formItemComponent;
      })}
    </Grid>
  );
};

export default CustomSurveyItem;
