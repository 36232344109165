import Image from '../../components/Image';
import { ConsultType } from '../../enum/ConsultEnum';
import { ChipStatus } from '../common/componentUI/commonStyleComponents';
import './waitingItem.scss';
import './waitingItem.scss';
import { ROLES_NAMES_SPECIAL } from '@/constants/roles';
import { doctorStatus } from '@/enum/RequestEnum';
import { loadFromStorage } from '@/helpers/StorageHelper';
import { Paper, Divider, Fab, Chip, makeStyles } from '@material-ui/core';
import {
  CallRounded,
  CheckCircleRounded,
  HighlightOffRounded,
} from '@material-ui/icons';
import { Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const { Text } = Typography;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const WaitingItem = ({
  data,
  doVerify,
  doCalling,
  doResetVerifyData,
  roleName,
  setPartner,
}) => {
  const callPatient = async () => {
    // doVerify({ data });
    setPartner({
      id: data.UserId,
      name: data.FullName,
      imageUrl: null,
    });

    if (data.IdentityStatus !== doctorStatus.Approved) {
      doVerify({ data });
    } else {
      // doResetVerifyData();
      if (data.BackCorperatePlan && data.FrontCorperatePlan) {
        doVerify({ data });
      } else {
        doCalling({ data });
      }
    }
  };

  const isShowSymptoms = data.Symptoms;

  const classes = useStyles();

  const renderDateTime = (dateTime) => {
    let dateFormat, timeFormat;

    dateFormat = moment(dateTime).format('DD-MM-YYYY');
    timeFormat = moment(dateTime).format('LT');

    return `${dateFormat}, ${timeFormat}`;
  };

  return (
    <Paper elevation={3} style={containerStyle}>
      <Section>
        <Text
          style={{
            fontSize: 24,
            fontWeight: '600',
            color: '#192637',
            marginLeft: 4,
          }}
        >
          {data.FullName}
        </Text>
        <div className={classes.root}>
          <ChipStatus>{ConsultType[data.ConsultType]}</ChipStatus>
          {data.AppointmentDate && (
            <Chip
              size="small"
              label={renderDateTime(data.AppointmentDate)}
              color="primary"
            />
          )}
          {data.ConsultType === 'TeleArt' && (
            <Chip
              size="small"
              label={data.HaveAppointment ? 'Yes' : 'No'}
              color={data.HaveAppointment ? 'primary' : 'secondary'}
              variant="outlined"
            />
          )}
        </div>
        <table>
          <InfoContainer>
            <tr>
              <td style={infoStyle}>ID Number</td>
              <td>{data.IdentityNumber}</td>
            </tr>
            <tr>
              <td style={infoStyle}>Age</td>
              <td>{`${data.Age} years old`}</td>
            </tr>
            <tr>
              <td style={infoStyle}>Gender</td>
              <td>{data.Gender}</td>
            </tr>
            {isShowSymptoms && (
              <>
                <tr>
                  <td style={infoStyle}>Symptoms</td>
                  <td>{data.Symptoms}</td>
                </tr>
              </>
            )}

            <tr>
              <td style={infoStyle}>Drug Allergy</td>
              <BreakWordContainer>
                {!isEmpty(data.DrugAllergyDetails)
                  ? data.DrugAllergyDetails
                  : 'No'}
              </BreakWordContainer>
            </tr>
            {data.CorperatePlan && (
              <tr>
                <td style={infoStyle}>Corporate Plan</td>
                <td>{data.CorperatePlan}</td>
              </tr>
            )}
            {data.PromotionCode && (
              <>
                <tr>
                  <td style={infoStyle}>Promotion</td>
                  <td>{data.PromotionCode}</td>
                </tr>
                <tr>
                  <td style={infoStyle}>Promotion Description</td>
                  <td>{data.PromotionDescription}</td>
                </tr>
              </>
            )}
          </InfoContainer>
        </table>
      </Section>
      {data.IdentityStatus === doctorStatus.Approved ? null : (
        <>
          <Divider orientation="vertical" flexItem />
          <Section>
            <span
              style={{
                color: '#8F9BA9',
                fontSize: 18,
              }}
            >
              ID Number:
              <span
                style={{
                  marginLeft: 10,
                  color: '#192637',
                  fontWeight: 600,
                }}
              >
                {data.IdentityNumber}
              </span>
            </span>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ marginRight: 20 }}>
                <span style={{ color: '#192637' }}>ID Front</span>
                <LabeledImage
                  src={data.FrontIdentityUrl}
                  alt="Client uploaded ID's frontside"
                  flag={data.IdentityStatus === 'Approved'}
                />
              </div>
              <div>
                <span style={{ color: '#192637' }}>ID Back</span>

                <LabeledImage
                  src={data.BackIdentityUrl}
                  alt="Client uploaded ID's backside"
                  flag={data.IdentityStatus === 'Approved'}
                />
              </div>
            </div>
          </Section>
        </>
      )}

      <Divider orientation="vertical" flexItem />
      <Section
        style={{
          flexDirection: 'row',
          padding: 0,
          flex: data.IdentityStatus === doctorStatus.Approved ? 2 : 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: '#8F9BA9',
              fontSize: 18,
            }}
          >
            Wait time
          </div>
          <Timer time={data.JoinedTime} />
        </div>
        {ROLES_NAMES_SPECIAL.includes(roleName) && (
          <div
            style={{
              display: 'flex',
              width: '50%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#A9A9A9',
            }}
          >
            <Fab
              style={{ backgroundColor: '#AD5E99', color: 'white' }}
              aria-label="add"
              onClick={callPatient}
            >
              <CallRounded />
            </Fab>
          </div>
        )}
      </Section>
    </Paper>
  );
};

const Timer = ({ time }) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const serverTimeOffset = loadFromStorage('serverTimeOffset');
    const joinedTimestamp = moment.utc(time).unix() * 1000;

    const intervalId = setInterval(() => {
      const currentTimestamp = moment.utc().unix() * 1000 + serverTimeOffset;
      setDuration(() => currentTimestamp - joinedTimestamp);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <div
      style={{
        color: '#AD5E99',
        fontSize: 48,
        fontWeight: 'bold',
      }}
    >
      {moment.utc(duration).format('mm:ss')}
    </div>
  );
};

const Section = ({ children, style = {} }) => {
  return (
    <div
      className="section-container"
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const LabeledImage = ({ flag = true, alt, src }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Image src={src} alt={alt} className="styled-image" />
      <div
        className="image-label"
        style={{
          backgroundColor: flag ? '#34AA7F' : '#A9A9A9',
          color: 'white',
        }}
      >
        {!flag ? (
          <>
            <HighlightOffRounded style={{ fontSize: 14, marginRight: 5 }} />
            Not Verified
          </>
        ) : (
          <>
            <CheckCircleRounded style={{ fontSize: 14, marginRight: 5 }} />
            Verified
          </>
        )}
      </div>
    </div>
  );
};

const containerStyle = {
  maxHeight: 600,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 10,
  overflow: 'hidden',
};

const infoStyle = {
  color: '#8F9BA9',
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
};
const InfoContainer = styled.tbody`
  font-size: 14px;
  color: '#192637';
`;

const BreakWordContainer = styled.td`
  word-break: break-word;
  white-space: pre-line;
`;

export default WaitingItem;
