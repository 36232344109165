import { LIST_FIELD, FIELD_ARRAY_VALUE } from '../constants';
import { filterEmptyData, validateCategory } from '../utils';
import AnswerItem from './AnswerItem';
import { Add, CloseIcon } from '@/assets/svg';
import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Box, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { cloneDeep, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  text: {
    fontFamily: 'Lato',
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
  },
  paperWidthMd: {
    maxWidth: 800,
  },
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: 24,
    minHeight: 600,
  },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))(DialogActions);
const CustomTitleLabel = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666',
  },
}))(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },

    '& p': {
      marginTop: 1,
    },
  },
})(TextField);

const CustomBox = withStyles((theme) => ({
  root: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))(Box);

const ErrorText = withStyles((theme) => ({
  root: {
    marginLeft: 14,
  },
}))(Typography);

const SurveyModal = ({
  open,
  onClose,
  onSubmit,
  selectedItem,
  typeModal,
  isCreateQues,
}) => {
  const [formValue, setFormValue] = useState({
    questions: get(selectedItem, 'item.questions', [
      { answers: [], answerType: 'FreeText' },
    ]),
  });
  const [errors, setErrors] = useState({});
  const [hasOther, setHasOther] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedItem.item)) {
      const value = cloneDeep(selectedItem.item);
      const hasOrtherAnsw = !isEmpty(
        value.answers.filter((ans) => ans.isOther)
      );

      if (hasOrtherAnsw) setHasOther(hasOrtherAnsw);
      setFormValue({
        questions: [value].map((item) => ({
          ...item,
          question: convertHTMLtoText(item.question),
          questionDesc: convertHTMLtoText(item.questionDesc),
        })),
      });
    }
  }, [selectedItem.item]);

  const convertHTMLtoText = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const checkIfHTMLString = (string) => /<[^>]*>/.test(string);

  const handleSubmit = () => {
    const error = validateCategory(formValue);

    if (isEmpty(error)) {
      const questions = filterEmptyData(formValue.questions).map((ques) => ({
        ...ques,
        question: checkIfHTMLString(ques.question)
          ? ques.question
          : `<p>${ques.question}</p>`,
        questionDesc: checkIfHTMLString(ques.questionDesc)
          ? ques.questionDesc
          : `<p>${ques.questionDesc}</p>`,
      }));

      onSubmit(typeModal, { ...formValue, questions }, selectedItem.position);
    } else {
      setErrors(error);
    }
  };

  // change question info: name, description, type
  const changeQuestionInfo = (key, position) => (e) => {
    let questions = [...formValue.questions];
    questions[position][key] = e.target.value;

    //reset answer array if change type
    if (key === 'answerType') {
      if (!isEmpty(questions[position].answers))
        questions[position].answers = questions[position].answers.map(
          (item) => ({
            ...item,
            isRemoved: true,
          })
        );
      setHasOther(false);
    }
    setFormValue({ ...formValue, questions });
  };

  // change answers option
  const changeAnswerOption = (questionPosition, action, index, event, key) => {
    let questions = [...formValue.questions];
    let answers = questions[questionPosition].answers || [];

    switch (action) {
      case TYPE_MODAL.Create:
        answers.push({
          isOther: false,
          answer: '',
          isAdditional: false,
          additionalNote: '',
        });

        break;
      case TYPE_MODAL.Other:
        answers.push({
          isOther: true,
          answer: 'Other',
          isAdditional: false,
          additionalNote: '',
        });
        setHasOther(true);
        break;
      case TYPE_MODAL.Edit:
      case TYPE_MODAL.EditAddition:
        answers[index][key] = event.target.value;
        break;
      case TYPE_MODAL.EditCheck:
        answers[index].isAdditional = event.target.checked;
        break;
      case TYPE_MODAL.Delete:
        if (answers[index].isOther) {
          // reset orther flag isOther
          answers[index].isOther = false;
          setHasOther(false);
        }
        answers[index].isRemoved = true;
        break;
      default:
        break;
    }

    answers = answers.map((item, index) => ({
      ...item,
      order: index,
    }));

    setFormValue({ ...formValue, questions });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    let questions = [...formValue.questions];

    const items = Array.from(questions[0].answers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    questions[0].answers = items.map((item, index) => ({
      ...item,
      order: index,
    }));

    setFormValue({ ...formValue, questions });
  };

  return (
    <CustomDialog
      maxWidth="md"
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>
        {isCreateQues ? 'Add' : 'Edit'} question
      </CustomDialogTitle>
      <Divider light />
      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          {formValue?.questions.map(
            (ques, index) =>
              !ques.isRemoved && (
                <>
                  <Grid item xs={12}>
                    <CustomTitleLabel>Question title</CustomTitleLabel>
                    <CustomTextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter here"
                      error={!!get(errors, `questions.${index}.question`)}
                      helperText={get(errors, `questions.${index}.question`)}
                      value={ques.question}
                      onChange={changeQuestionInfo('question', index)}
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTitleLabel>Description</CustomTitleLabel>
                    <CustomTextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter here"
                      error={!!get(errors, `questions.${index}.questionDesc`)}
                      helperText={get(
                        errors,
                        `questions.${index}.questionDesc`
                      )}
                      value={ques.questionDesc}
                      onChange={changeQuestionInfo('questionDesc', index)}
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTitleLabel style={{ marginBottom: 8 }}>
                      Type
                    </CustomTitleLabel>
                    <CustomSelectContainer
                      label="label"
                      isFullWidth
                      filterValue={ques.answerType}
                      data={LIST_FIELD}
                      renderValue={
                        <>
                          {LIST_FIELD.find((it) => it.key === ques.answerType)
                            ?.label || 'Select'}
                        </>
                      }
                      height="48px"
                      isMutiple={false}
                      clearable={false}
                      onChange={changeQuestionInfo('answerType', index)}
                    />
                    {get(errors, `questions.${index}.answerType`) && (
                      <ErrorText variant="caption" color="error">
                        {get(errors, `questions.${index}.answerType`)}
                      </ErrorText>
                    )}
                  </Grid>
                  {FIELD_ARRAY_VALUE.includes(ques.answerType) ? (
                    <Grid item xs={12}>
                      <CustomTitleLabel>Answer</CustomTitleLabel>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="answers">
                              {(provided) => (
                                <div
                                  {...provided?.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {ques.answers.map(
                                    (answer, i) =>
                                      !answer.isRemoved && (
                                        <Draggable
                                          index={i}
                                          key={`answer-${i}`}
                                          draggableId={`answer-${i}`}
                                        >
                                          {(provided) => (
                                            <CustomBox
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <AnswerItem
                                                onChangeAnswer={
                                                  changeAnswerOption
                                                }
                                                value={answer}
                                                questionPos={index}
                                                answerPos={i}
                                                typeModal={typeModal}
                                                errors={errors}
                                              />
                                            </CustomBox>
                                          )}
                                        </Draggable>
                                      )
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Grid>
                        {typeModal !== TYPE_MODAL.View ? (
                          <Grid item xs={12}>
                            <Button
                              color="primary"
                              startIcon={<Add />}
                              onClick={() =>
                                changeAnswerOption(index, TYPE_MODAL.Create)
                              }
                            >
                              Add option
                            </Button>
                            {!hasOther && (
                              <>
                                {`  or  `}
                                <Button
                                  color="primary"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    changeAnswerOption(index, TYPE_MODAL.Other)
                                  }
                                >
                                  Add "Other"
                                </Button>
                              </>
                            )}
                          </Grid>
                        ) : (
                          <>
                            <CustomTextField
                              fullWidth
                              variant="outlined"
                              multiline
                              rowsMax={4}
                              placeholder="Type your answer"
                              disabled
                              value={ques.question || ''}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <CustomTitleLabel style={{ marginBottom: 8 }}>
                        Answer
                      </CustomTitleLabel>
                      <CustomTextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type your answer"
                        disabled
                        multiline
                        rows={3}
                      />
                    </Grid>
                  )}
                </>
              )
          )}
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Add
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default SurveyModal;
